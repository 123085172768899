import { makeStyles } from '@material-ui/core/styles';
import Paper from '@mui/material/Paper';
import React from 'react';

const useStyles = makeStyles(theme => ({
  [theme.breakpoints.down('sm')]: {
    img:{
      transform: "scale(0.6, 0.6)"
    }
  },
  [theme.breakpoints.up('md')]: {
    img:{
      transform: "scale(0.6, 0.6)"
    }
  },
  [theme.breakpoints.up('lg')]: {
    img:{
      transform: "scale(0.8, 0.8)"
    }
  },
  paper:{
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
}));

export const AircraftDiagram = (props) => {
  const classes = useStyles();
  const { aircraftName } = props;
  return (
    <Paper className = {classes.paper} elevated = {10}>

    </Paper>
  );
}


export default AircraftDiagram
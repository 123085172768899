//https://mui.com/material-ui/material-icons/
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import MapIcon from '@mui/icons-material/Map';
import CloudIcon from '@mui/icons-material/Cloud';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import RadioIcon from '@mui/icons-material/Radio';
import ArticleIcon from '@mui/icons-material/Article';
import CellTowerIcon from '@mui/icons-material/CellTower';
import LuggageIcon from '@mui/icons-material/Luggage';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import AssessmentIcon from '@mui/icons-material/Assessment';
import DoorSlidingIcon from '@mui/icons-material/DoorSliding';
import AddRoadIcon from '@mui/icons-material/AddRoad';
import FlightLandIcon from '@mui/icons-material/FlightLand';
import CookieIcon from '@mui/icons-material/Cookie';
import FeedbackIcon from '@mui/icons-material/Feedback';
import ReceiptIcon from '@mui/icons-material/Receipt';
import DescriptionIcon from '@mui/icons-material/Description';

import AircraftDiagramTab from "tabs/AircraftDiagramTab";
import AirportMapTab from "tabs/AirportMapTab";
import FlightDisruptionsTab from "tabs/FlightDisruptionsTab";
import AllTranscriptsTab from "tabs/AllTranscriptsTab";
import GroundOpsTab from 'tabs/GroundOpsTab';
import WeatherTab from 'tabs/WeatherTab';
import ADSBModeSTab from 'tabs/ADSBModeSTab';
import FlightStatsTab from "tabs/FlightStatsTab";
import CargoTab from "tabs/CargoTab";
import RevenueManagementTab from "tabs/RevenueManagementTab";
import ConcessionsTab from "tabs/ConcessionsTab";
import ParkingQueueTab from "tabs/ParkingQueueTab";
import ParkingReportsTab from "tabs/ParkingReportsTab";
import GateManagementTab from "tabs/GateManagementTab";
import SurfaceManagementTab from "tabs/SurfaceManagementTab";
import ShuttleTrackingTab from "tabs/ShuttleTrackingTab";
import ProductSalesTab from "tabs/ProductSalesTab";
import FeedbackSurveysTab from "tabs/FeedbackSurveysTab";
import InvoiceMgmtTab from "tabs/InvoiceMgmtTab";
import InvoiceReportsTab from "tabs/InvoiceReportsTab";

const TabRoutes = [
  {
    path: '/gate-management',
    sidebarName: 'Gate Management',
    component: GateManagementTab,
    icon: DoorSlidingIcon
  },
  {
    path: '/revenue-management',
    sidebarName: 'Revenue Management',
    component: RevenueManagementTab,
    icon: AttachMoneyIcon
  },
  {
    path: '/parking-que',
    sidebarName: 'Parking Queue',
    component: ParkingQueueTab,
    icon: HourglassEmptyIcon
  },
  {
    path: '/parking-reports',
    sidebarName: 'Parking Reports',
    component: ParkingReportsTab,
    icon: AssessmentIcon
  },
  {
    path: '/shuttle-tracking',
    sidebarName: 'Shuttle Tracking',
    component: ShuttleTrackingTab,
    icon: AirportShuttleIcon
  },
  {
    path: '/flight-disruptions',
    sidebarName: 'Flight Disruptions',
    component: FlightDisruptionsTab,
    icon: ReportProblemIcon
  },
  {
    path: '/concessions',
    sidebarName: 'Concessions',
    component: ConcessionsTab,
    icon: FastfoodIcon
  },
  {
    path: '/surface-management',
    sidebarName: 'Surface Management',
    component: SurfaceManagementTab,
    icon: AddRoadIcon
  },
  {
    path: '/flight-stats',
    sidebarName: 'Flight Stats',
    component: FlightStatsTab,
    icon: ArticleIcon
  },
  {
    path: '/cargo',
    sidebarName: 'Cargo',
    component: CargoTab,
    icon: LuggageIcon
  },
  {
    path: '/airport-map',
    sidebarName: 'Airport Map',
    component: AirportMapTab,
    icon: MapIcon
  },
  {
    path: '/ads-b-mode-s',
    sidebarName: 'ADS-B/Mode S',
    component: ADSBModeSTab,
    icon: CellTowerIcon
  },
  {
    path: '/ground-ops',
    sidebarName: 'Ground Ops',
    component: GroundOpsTab,
    icon: FlightLandIcon
  },
  {
    path: '/aircraft-diagram',
    sidebarName: 'Aircraft Identification',
    component: AircraftDiagramTab,
    icon: FlightTakeoffIcon
  },
  {
    path: '/weather',
    sidebarName: 'Weather',
    component: WeatherTab,
    icon: CloudIcon
  },
  {
    path: '/all-transcripts',
    sidebarName: 'All Transcripts',
    component: AllTranscriptsTab,
    icon: RadioIcon
  },
  {
    path: '/product-sales',
    sidebarName: 'Product Sales',
    component: ProductSalesTab,
    icon: CookieIcon
  },
  {
    path: '/feedback-surveys',
    sidebarName: 'Feedback Surveys',
    component: FeedbackSurveysTab,
    icon: FeedbackIcon
  },
  {
    path: '/invoice-management',
    sidebarName: 'Invoice Management',
    component: InvoiceMgmtTab,
    icon: ReceiptIcon
  },
  {
    path: '/invoice-reports',
    sidebarName: 'Invoice Reports',
    component: InvoiceReportsTab,
    icon: DescriptionIcon
  },
];
  
export default TabRoutes;
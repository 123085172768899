import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@mui/material';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
    PaymentElement,
    useElements,
    useStripe
} from "@stripe/react-stripe-js";
import { saveParkingQueueEvent } from 'api/endpoints/parkingQueue';
import { savePaymentLog, updateAccountBalance, updatePaymentIntent } from "api/endpoints/payments";
import { auth } from "api/firebase";
import React, { useState } from "react";
import { connect } from 'react-redux';
import { updateUser } from 'store/user/actions';

const useStyles = makeStyles({
    loadingContainer:{
      justifyContent: "center",
      display: "flex",
      padding: 10,
      alignSelf: "center",
      margin: "0 auto"
    },
    errorMessage:{
        paddingTop: 10,
        textAlign: "center"
    }
});

const PaymentModal = (props) => {
    const classes = useStyles();
    const { 
        user,
        paymentModalOpen, 
        setPaymentModalOpen, 
        paymentIntentId, 
        enterQueue, 
        setIsQueueStatusLoading,
        updateUser
    } = props;
    const stripe = useStripe();
    const elements = useElements();

    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [paymentAmount, setPaymentAmount] = useState(20);
    const [isPaymentValueError, setIsPaymentValueError] = useState(false)

    const minAmount = 20;
    const maxAmount = 10000;
  
    const handleChange = (event) => {
        const value = event.target.value;
      
        const numericValue = value.replace(/[^0-9]/g, '');
    
        setPaymentAmount(numericValue);

        if(numericValue > 10000 || numericValue < 20){
            setIsPaymentValueError(true)
        }else{
            setIsPaymentValueError(false)
        }
    };

    const formatCurrency = (value) => {
        if (!value) return '';
        const number = parseFloat(value);
        if (isNaN(number)) return '';
        return `$${number.toLocaleString()}`;
    };

    const handleClose = () => {
        if(setIsQueueStatusLoading){
            setIsQueueStatusLoading(false);
        }
        setPaymentModalOpen(false);
        setMessage(null)
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
    
        if (!stripe || !elements) {
          return;
        }
        
        if(paymentAmount < 20){
            setMessage("Please enter a payment value > $20");
            return;
        }
    
        setIsLoading(true);

        try{
            await updatePaymentIntent(paymentIntentId, paymentAmount)
            const payment = await stripe.confirmPayment({
                elements,
                redirect: "if_required",
                confirmParams: {
                  return_url: window.location.href,
                },
            });
            if(payment?.paymentIntent?.status){
                switch (payment.paymentIntent.status) {
                    case "succeeded":
                        setMessage("Payment succeeded!");
                        updateUser({ ...user, accountBalance: Number(user.accountBalance) + Number(paymentAmount)})
                        await updateAccountBalance("+", paymentAmount)
                        if(enterQueue){
                            setIsQueueStatusLoading(false);
                            await enterQueue()
                            await saveParkingQueueEvent({
                                uid: auth.currentUser?.uid,
                                airportCode: user.airportCode,
                                firstAndLastName: user.firstAndLastName,
                                companyName: user.companyName,
                                taxiNumber: user.taxiNumber,
                                event: "Entered Queue"
                            })
                        }
                        await savePaymentLog(user.airportCode, paymentAmount, "added")
                      break;
                    case "processing":
                        setMessage("Your payment is processing.");
                        break;
                    case "requires_payment_method":
                        setMessage("Your payment was not successful, please try again.");
                        break;
                    default:
                        setMessage("Something went wrong.");
                        break;
                }
            }else if (payment?.error){
                if (payment.error.type === "card_error" || payment.error.type === "validation_error") {
                    setMessage(payment.error.message);
                } else {
                    setMessage("An unexpected error occurred.");
                }
            }
        }catch(e){
            console.log(e)
            setMessage("An unexpected error occurred.");
        }

        setIsLoading(false);
    };

    return (
        <Dialog
            disableEnforceFocus
            open={paymentModalOpen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            {message !== "Payment succeeded!" && (
                <>
                    <DialogTitle id="alert-dialog-title">
                        Payment Information
                    </DialogTitle>

                    <ButtonGroup 
                        variant="outlined" 
                        aria-label="outlined button group"
                        style = {{
                            display: "flex",
                            justifyContent: "center"
                        }}
                    >
                        <Button onClick={()=> setPaymentAmount(20)}>$20</Button>
                        <Button onClick={()=> setPaymentAmount(40)}>$40</Button>
                        <Button onClick={()=> setPaymentAmount(60)}>$60</Button>
                        <Button onClick={()=> setPaymentAmount(80)}>$80</Button>
                    </ButtonGroup>

                    <div style = {{paddingLeft: 20, paddingRight: 20, paddingTop: 20}}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            value={formatCurrency(paymentAmount)}
                            onChange={handleChange}
                            helperText={`$${minAmount} min, $${maxAmount} max`}
                            error={isPaymentValueError} 
                        />
                    </div>
                </>
            )}

            <DialogContent>
                <form id="payment-form" onSubmit={handleSubmit}>
                    {message !== "Payment succeeded!" && <PaymentElement id="payment-element" />}
                    {message && <div id="payment-message" className={classes.errorMessage}>{message}</div>}
                </form>
            </DialogContent>
            <DialogActions>
                {isLoading ? 
                    <div style = {{padding: 10}}>
                        <CircularProgress/>
                    </div>
                : message !== "Payment succeeded!" && (!message || !isLoading || stripe || elements) ? 
                    <>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button disabled={isPaymentValueError} form='payment-form' type="submit">
                            Pay Now
                        </Button>
                    </>
                : 
                    <Button onClick={handleClose}>Done</Button>
                }
            </DialogActions>
        </Dialog>
    );
}

const mapDispatchToProps = {
    updateUser
}

const mapStateToProps = (state) =>{
    return{
        user: state.userReducer.user,
        airport: state.airportReducer.airport
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentModal)
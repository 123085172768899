export const isInZone = (latitude, longitude, polygon) => {
  let inside = false
  for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
    const xi = polygon[i]["lat"]; const yi = polygon[i]["lng"]
    const xj = polygon[j]["lat"]; const yj = polygon[j]["lng"]

    const intersect = ((yi > longitude) !== (yj > longitude)) &&
            (latitude < (xj - xi) * (longitude - yi) / (yj - yi) + xi)
    if (intersect) inside = !inside
  }

  return inside
};
import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import Box from '@mui/material/Box';
import UploadExcelFileCard from 'components/UploadExcelFileCard/UploadExcelFileCard';
import CreateInvoiceCard from 'components/CreateInvoiceCard/CreateInvoiceCard';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { DataGrid } from '@mui/x-data-grid';

const styles = {
  container: {
    margin: 3,
  },
};

const InvoiceMgmtTab = () => {
  const [monthlyReport, setMonthlyReport] = useState([]);
  const [taskData, setTaskData] = useState();
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [year, setYear] = useState(new Date().getFullYear());

  const columns = [
    { field: 'Name', headerName: 'Name', width: 150, editable: true },
    { field: 'Address', headerName: 'Address', width: 200, editable: true },
    { field: 'City', headerName: 'City', width: 120, editable: true },
    { field: 'State', headerName: 'State', width: 90, editable: true },
    { field: 'Zip', headerName: 'Zip', width: 90, editable: true },
    { field: 'Phone 1', headerName: 'Phone 1', width: 130, editable: true },
    { field: 'Phone 2', headerName: 'Phone 2', width: 130, editable: true },
    { field: 'Email', headerName: 'Email', width: 180, editable: true },
    { field: 'DL', headerName: 'DL', width: 100, editable: true },
    { field: 'Company Name', headerName: 'Company Name', width: 180, editable: true },
    { field: 'Card Number', headerName: 'Card Number', width: 150, editable: true },
    { field: 'Read At', headerName: 'Read At', width: 120, editable: true },
    { field: 'Plaza', headerName: 'Plaza', width: 130, editable: true },
    { field: 'License Plate State', headerName: 'License Plate State', width: 140, editable: true },
    { field: 'License Plate Number', headerName: 'License Plate Number', width: 160, editable: true },
    { field: 'Toll Tag Type', headerName: 'Toll Tag Type', width: 130, editable: true },
    { field: 'Data Source', headerName: 'Data Source', width: 130, editable: true },
    { field: 'Total Trips', headerName: 'Total Trips', width: 110, editable: true },
    { field: 'Trip Fees Beginning', headerName: 'Trip Fees Beginning', width: 160, editable: true },
    { field: 'Trip Fees Ending', headerName: 'Trip Fees Ending', width: 160, editable: true },
    { field: 'Trip Fee', headerName: 'Trip Fee', width: 100, editable: true },
    { field: ' Paid ', headerName: 'Paid', width: 100, editable: true },
    { field: 'Total Amount', headerName: 'Total Amount', width: 130, editable: true },
    { field: 'Invoice #', headerName: 'Invoice #', width: 120, editable: true },
    { field: 'Total Amount Due', headerName: 'Total Amount Due', width: 130, editable: true },
    { field: ' Date Due ', headerName: 'Date Due', width: 130, editable: true },
  ];  

  useEffect(() => {
    ReactGA.pageview('/invoice-mgmt');
  }, []);

  const handleCellEditCommit = (params) => {
    const { id, field, value } = params;

    setMonthlyReport((prevRows) => {
      const updatedRows = [...prevRows];
      const index = updatedRows.findIndex((row) => row.id === id) + 1;
      updatedRows[index] = {
        ...updatedRows[index],
        [field]: value,
      };
      return updatedRows;
    });
  };

  const convertToCSV = (rows, columns) => {
    const headerRow = columns.map(column => column.headerName).join(',');
  
    const dataRows = rows.map(row => 
      columns.map(column => row[column.field] || "").join(',')
    );
  
    return [headerRow, ...dataRows].join('\n');
  };

  const handleDownloadReport = () => {
    const csvDataForDownload = convertToCSV(monthlyReport, columns);
    const blob = new Blob([csvDataForDownload], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'Monthly Report.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  return (
    <Box sx={styles.container}>
      <UploadExcelFileCard 
        setMonthlyReport={setMonthlyReport} 
        setTaskData={setTaskData}
        setMonth={setMonth}
        setYear={setYear}
        month={month}
        year={year}
      />

      <Paper sx={{ mt: 3, p: 2 }}>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center', 
          mb: 2,
          flexDirection: {
            xs: 'column',
            sm: 'row'   
          },
        }}>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            Monthly Report
          </Typography>
          <Button variant="outlined" color="primary" sx={{ mr: 1 }} onClick={handleDownloadReport}>
            Download Report
          </Button>
        </Box>

        <DataGrid
          rows={monthlyReport.map((row, index) => ({ id: index, ...row }))}
          columns={columns}
          onCellEditCommit={handleCellEditCommit}
          disableRowSelectionOnClick
          sx={{
            height: 520,
            '& .MuiDataGrid-cell': {
              borderRight: '1px solid #e0e0e0',
            },
          }}
          pagination={false}
          autoHeight={false}
          hideFooter
        />
      </Paper>

      <CreateInvoiceCard 
        monthlyReport={monthlyReport} 
        csvData={taskData}
        month={month}
        year={year}
      />
    </Box>
  );
};

export default InvoiceMgmtTab;
import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { styled } from '@mui/system';
import { UploadFile } from '@mui/icons-material';
import { createMonthlyReport } from "api/endpoints/reportingAutomation";
import Papa from 'papaparse';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

const FileInput = styled('input')({
    display: 'none',
});

const StyledPaper = styled(Paper)(() => ({
    textAlign: 'center',
    cursor: 'pointer',
    '&:hover': {
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        background: "rgb(243 243 243)"
    },
}));

const styles = {
    dateSelection: {
        margin: 25
    }
}

const UploadExcelFileCard = ({ setMonthlyReport, setTaskData, year, month, setYear, setMonth }) => {
    const months = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    const years = [year, year - 1, year - 2];

    const [selectedFile, setSelectedFile] = useState(null);
    const [uploading, setUploading] = useState(false);

    const parseCsvFile = (file) => {
        setUploading(true);
        Papa.parse(file, {
            header: true,
            skipEmptyLines: true,
            complete: async (result) => {
                const dataArray = result.data;
                try {
                    if (dataArray[0]?.['Name'] && dataArray[0]?.['Address'] && dataArray[0]?.['License Plate Number'] && dataArray[0]?.['Card Number']) {
                        setMonthlyReport(dataArray);
                    } else if (dataArray[0]?.['Read At'] && dataArray[0]?.['Plaza'] && dataArray[0]?.['License Plate State'] && dataArray[0]?.['License Plate Number']) {
                        const monthlyReport = await createMonthlyReport(dataArray, month, year);
                        setTaskData(dataArray);
                        setMonthlyReport(monthlyReport.data.report);
                    } else {
                        alert("File does not match the expected format.")
                    }
                } catch (error) {
                    console.log("Error creating monthly report:", error);
                } finally {
                    setUploading(false)
                }
            },
            error: (error) => {
                console.log("Error parsing CSV file:", error);
            },
        });
    };

    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
            parseCsvFile(file);
        }
    };

    const handlePaperClick = () => {
        document.getElementById('file-input').click();
    };

    return (
        <Paper elevation={3}>
            <Box sx={{display: "flex"}}>
                <FormControl variant="outlined" fullWidth style={styles.dateSelection}>
                    <InputLabel>Month</InputLabel>
                    <Select
                        value={month}
                        onChange={(e) => setMonth(Number(e.target.value))} 
                        label="Month"
                    >
                        {months.map((monthName, index) => (
                            <MenuItem key={index} value={index + 1}> 
                                {monthName}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl variant="outlined" fullWidth style={styles.dateSelection}>
                    <InputLabel>Year</InputLabel>
                    <Select
                        value={year}
                        onChange={(e) => setYear(e.target.value)}
                        label="Year"
                    >
                        {years.map((yearSelected) => (
                            <MenuItem key={yearSelected} value={yearSelected}>
                                {yearSelected}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>

            <StyledPaper onClick={handlePaperClick}>
                <FileInput
                    id="file-input"
                    type="file"
                    accept=".csv"
                    onChange={handleFileUpload}
                />
                <UploadFile sx={{ fontSize: 60, color: "green", pt: 2}} />
                <Typography variant="h6">
                    {selectedFile ? `${selectedFile.name} file uploaded` : 'Upload Task Data or Monthly Report'}
                </Typography>
                <Typography variant="body2" color="textSecondary" sx={{pb: 6}}>
                    Only .csv files are supported
                </Typography>
                {uploading && <CircularProgress size={24} style={{ marginTop: '10px' }} />}
            </StyledPaper>
        </Paper>
    );
};

export default UploadExcelFileCard;
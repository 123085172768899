import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { signin, signup } from 'api/endpoints/auth';
import { auth } from "api/firebase";
import Icon from 'assets/img/transparent.png';
import { signOut } from "firebase/auth";
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { updateUser } from 'store/user/actions';

const useStyles = makeStyles(theme => ({
  paper: {
    margin: theme.spacing(4, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const VerifyUser = (props) => {
  const classes = useStyles();
  const { user, recapthaResponse, updateUser } = props;
  const [loading, setLoading] = useState(false)

  const verifyOtp = async(value) => {
    if(value.target.value.length > 5){
      setLoading(true)
      try{
        await recapthaResponse.confirm(value.target.value)
      }catch(e){
        console.log(e)
        setLoading(false)
        alert(e.message.replace("Firebase: ", ""));
        return
      }
      try{
        let userInfo = {
          uid: auth.currentUser?.uid, 
          airportCode: user?.airportCode,
          firstAndLastName: user?.firstAndLastName,
          companyName: user?.companyName,
          taxiNumber: user?.taxiNumber,
          phoneNumber: user?.phoneNumber,
          email: user?.email,
          accountBalance: user?.accountBalance,
          licensePlate: user?.licensePlate
        }
        const getUserInfo = await signin();
        if(getUserInfo.data.data.length < 1){
          await signup(userInfo)
          updateUser(userInfo)
        }else{
          updateUser(getUserInfo.data.data[0])
        }
      }catch(e){
        await signOut(auth);
        console.log(e)
      }
      setLoading(false)
    }
  };

  return (
    <div className={classes.paper}>
      <Avatar className={classes.avatar} src={Icon}/>
      <Typography>Hello, Please enter code sent.</Typography>
      {
        loading ? <CircularProgress/> :
        recapthaResponse ? 
          <TextField
            variant="outlined"
            margin="normal"
            type='number'
            required
            fullWidth
            label="6 digit code"
            autoFocus
            onChange={(value) => verifyOtp(value)}
          />
        : null
      }
    </div>
  );
}

const mapDispatchToProps = {
  updateUser
}

const mapStateToProps = (state) =>{
  return{
    user: state.userReducer.user
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VerifyUser)
import React, { useState } from 'react';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import { Card, CardContent, CardMedia, Box, Grid, IconButton, Paper, Typography } from '@mui/material';
import {
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js';
import Chart from 'react-apexcharts';
import { Line } from 'react-chartjs-2';
import StatisticCard from 'components/StatisticCard/StatisticCard';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const feedbackData = [
  {
    id: 1,
    imageUrls: ['/img/brokentoilet.webp', '/img/brokentoilet.webp'],
    timestamp: 1696608000,
    issue: 'Poor Facilities',
    issueDesc: 'The toilet here is broken',
    experienceRating: 'Very Dissatisfied'
  },
  {
    id: 2,
    imageUrls: ['/img/airConditioning.jpeg', '/img/airConditioning.jpeg'],
    timestamp: 1696521600,
    issue: 'Poor Facilities',
    issueDesc: 'It is very hot near gate 10',
    experienceRating: 'Dissatisfied'
  },
  {
    id: 3,
    imageUrls: ['/img/angryHomelessMan.jpeg', '/img/brokentoilet.webp'],
    timestamp: 1696435200,
    issue: 'Security Issues',
    issueDesc: 'There is a homeless yelling on the curb',
    experienceRating: 'Dissatisfied'
  },
  {
    id: 4,
    imageUrls: ['/img/angryPassenger.jpeg', '/img/airConditioning.jpeg'],
    timestamp: 1696348800,
    issue: 'Rude Staff',
    issueDesc: 'The curb side manager kept yelling at me',
    experienceRating: 'Very Dissatisfied'
  },
  {
    id: 5,
    imageUrls: ['/img/violentAttackOnCurb.jpeg', '/img/brokentoilet.webp'],
    timestamp: 1696262400,
    issue: 'Security Issues',
    issueDesc: 'There is someone attacking another person on the curb',
    experienceRating: 'Very Dissatisfied'
  },
  {
    id: 6,
    imageUrls: ['/img/lateBus.jpeg', '/img/airConditioning.jpeg'],
    timestamp: 1696176000,
    issue: 'Other',
    issueDesc: 'The bus is very late',
    experienceRating: 'Dissatisfied'
  }
]

const renderSentimentIcon = (rating) => {
  switch (rating) {
    case 'Very Satisfied':
      return <SentimentVerySatisfiedIcon style={{ color: '#66DA26' }} />;
    case 'Satisfied':
      return <SentimentSatisfiedAltIcon style={{ color: '#228B22' }} />;
    case 'Dissatisfied':
      return <SentimentDissatisfiedIcon style={{ color: '#E91E63' }} />;
    case 'Very Dissatisfied':
      return <SentimentVeryDissatisfiedIcon style={{ color: '#FF4560' }} />;
    default:
      return null;
  }
};


const generateChartData = () => {
  const labels = ['Oct 1', 'Oct 2', 'Oct 3', 'Oct 4', 'Oct 5', 'Oct 6', 'Oct 7']; // 7-day labels

  return {
    labels,
    datasets: [
      {
        label: 'Very Satisfied',
        data: [150, 200, 250, 300, 270, 320, 350],
        borderColor: '#66DA26',
        backgroundColor: '#66DA26',
        tension: 0.3,
        fill: false,
      },
      {
        label: 'Satisfied',
        data: [100, 120, 140, 150, 180, 210, 220],
        borderColor: '#228B22', 
        backgroundColor: '#228B22',
        tension: 0.3,
        fill: false,
      },
      {
        label: 'Dissatisfied',
        data: [50, 60, 90, 70, 80, 100, 120],
        borderColor: '#E91E63', 
        backgroundColor: '#E91E63',
        tension: 0.3,
        fill: false,
      },
      {
        label: 'Very Dissatisfied',
        data: [30, 40, 50, 60, 50, 70, 80],
        borderColor: '#FF4560', 
        backgroundColor: '#FF4560',
        tension: 0.3,
        fill: false,
      },
    ]
  };
};

const chartOptions = {
  chart: {
    type: 'bar',
    stacked: true,
    toolbar: {
      show: true
    }
  },
  plotOptions: {
    bar: {
      horizontal: false,
    },
  },
  xaxis: {
    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  },
  yaxis: {
    title: {
      text: 'Number of Surveys',
    }
  },
  legend: {
    position: 'bottom',
    offsetY: 0
  },
  fill: {
    opacity: 1
  },
  dataLabels: {
    enabled: false
  },
  colors: ['#66DA26', '#228B22', '#E91E63', '#FF4560']
};


const chartSeries = [
  {
    name: 'Very Satisfied',
    data: [50, 80, 60, 100, 120, 150, 200, 170, 190, 220, 240, 210]
  },
  {
    name: 'Satisfied',
    data: [30, 50, 40, 60, 80, 100, 120, 110, 130, 140, 150, 130]
  },
  {
    name: 'Dissatisfied',
    data: [10, 20, 15, 30, 40, 60, 80, 70, 50, 60, 70, 50]
  },
  {
    name: 'Very Dissatisfied',
    data: [5, 10, 8, 15, 20, 25, 30, 25, 35, 40, 45, 30]
  }
];

const FeedbackSurveysTab = () => {
  const lineChartData = generateChartData();
  const [liked, setLiked] = useState(null);

  const handleThumbUpClick = () => {
    setLiked(liked === 'up' ? null : 'up');
  };

  const handleThumbDownClick = () => {
    setLiked(liked === 'down' ? null : 'down');
  };

  return (
    <Grid container spacing={3} style={{ padding: 20 }}>
      <Grid item xs={12}>
        <Paper elevation={3} style={{ padding: 20 }}>
          <Typography variant="h4" align="center">
            Feedback Reports
          </Typography>
        </Paper>
      </Grid>

      <Grid item xs={12} sm={6} spacing={3}>
        <StatisticCard
          title="Surveys Submitted Today"
          count={45}
          backgroundColor="#008000"
          backgroundAfterColor="#90ee90"
        />
      </Grid>

      <Grid item xs={12} sm={6} spacing={3}>
        <StatisticCard
          title="Surveys Submitted All-Time"
          count={23595}
          backgroundColor="#006400"
          backgroundAfterColor="#004d00"
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <Paper elevation={3} style={{ padding: 20 }}>
          <Typography variant="h6" gutterBottom>
            Satisfaction Bar Chart
          </Typography>
          <Chart options={chartOptions} series={chartSeries} type="bar" height={400} />
        </Paper>
      </Grid>

      <Grid item xs={12} md={6}>
        <Paper elevation={3} style={{ padding: 20 }}>
          <Typography variant="h6" gutterBottom>
              Satisfaction Over Time
          </Typography>
          <Line data={lineChartData} />
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Paper elevation={3} style={{ padding: 20 }}>
          <Typography variant="h6" gutterBottom>
            Feedback Form Entries
          </Typography>

          <Grid container spacing={3}>
            {feedbackData.map((item) => (
              <Grid item xs={12} sm={6} md={4} key={item.id}>  {/* Ensure grid items for cards */}
                <Card>
                  <CardMedia
                    component="img"
                    height="140"
                    image={item.imageUrls[0]}  // Use the first image from imageUrls
                    alt="Feedback image"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        {item.issue}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        {item.issueDesc}
                    </Typography>
                    <div style={{ marginTop: '10px' }}>
                        {renderSentimentIcon(item.experienceRating)}
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Paper elevation={3} style={{ padding: 20 }}>
          <Typography variant="h6">
            AI Summary of Feedback
          </Typography>
          <Typography variant="subtitle2" gutterBottom sx={{pb: 1}}>
            (from the data gathered in the last 24 hours)
          </Typography>
          <Typography variant="body1" gutterBottom>
            Multiple users have reported bathroom issues, specifically broken toilets, at the airport. The main problem seems to be occurring around gate 10, based on feedback from passengers. This issue has caused dissatisfaction for many travelers using the facilities. Immediate maintenance attention is recommended to resolve the problem quickly.
          </Typography>

          <Box sx={{display: "flex", justifyContent: "flex-end", alignItems: "center"}}>
            <Typography variant="subtitle2">
              Did this help solve the problem?
            </Typography>
            <IconButton onClick={handleThumbUpClick} color={liked === 'up' ? 'primary' : 'default'}>
              <ThumbUpIcon />
            </IconButton>
            <IconButton onClick={handleThumbDownClick} color={liked === 'down' ? 'primary' : 'default'}>
              <ThumbDownIcon />
            </IconButton>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default FeedbackSurveysTab;

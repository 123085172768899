import React, { useEffect, useState, useRef, useCallback } from 'react';
import { connect } from 'react-redux';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import TimeFrameContainer from 'components/TimeFrameContainer/TimeFrameContainer';
import { convertToLocal } from 'utils/timeConversions';

const dummyData = [
    {
        "Name": "John Doe",
        "Address": "123 Main St",
        "City": "New York",
        "State": "NY",
        "Zip": "10001",
        "Phone 1": "123-456-7890",
        "Phone 2": "707-707-7070",
        "Email": "john@example.com",
        "DL": "DL-100",
        "Company Name": "John Corp",
        "Card Number": "DFW.02241720",
        "Read At": "5/16/24 7:50",
        "Plaza": "Plaza 1",
        "License Plate State": "NY",
        "License Plate Number": "ABC1230",
        "Toll Tag Type": "Regular",
        "Data Source": "GK",
        "Total Trips": 1,
        "Trip Fees Beginning": "01/01/2024",
        "Trip Fees Ending": "01/31/2024",
        "Trip Fee": 2,
        "Paid": true,
        "Total Amount": 2,
        "Invoice #": 1002,
        "Total Amount Due": 2,
        "Date Due": "10/31/2024",
        "timestamp": 1729536697
    },
    {
        "Name": "Jane Smith",
        "Address": "456 Oak Ave",
        "City": "Los Angeles",
        "State": "CA",
        "Zip": "90001",
        "Phone 1": "987-654-3210",
        "Phone 2": "777-777-7777",
        "Email": "jane@example.com",
        "DL": "DL-101",
        "Company Name": "Jane Corp",
        "Card Number": "DFW.02241720",
        "Read At": "5/16/24 7:50",
        "Plaza": "Plaza 2",
        "License Plate State": "CA",
        "License Plate Number": "ABC1231",
        "Toll Tag Type": "Regular",
        "Data Source": "GK",
        "Total Trips": 1,
        "Trip Fees Beginning": "01/01/2024",
        "Trip Fees Ending": "01/31/2024",
        "Trip Fee": 2,
        "Paid": true,
        "Total Amount": 2,
        "Invoice #": 1003,
        "Total Amount Due": 2,
        "Date Due": "10/31/2024",
        "timestamp": 1729536697
    },
    {
        "Name": "Alice Johnson",
        "Address": "789 Pine Rd",
        "City": "Chicago",
        "State": "IL",
        "Zip": "60601",
        "Phone 1": "555-555-5555",
        "Phone 2": "333-333-3333",
        "Email": "alice@example.com",
        "DL": "DL-102",
        "Company Name": "Alice Corp",
        "Card Number": "DFW.02241720",
        "Read At": "5/16/24 7:50",
        "Plaza": "Plaza 3",
        "License Plate State": "IL",
        "License Plate Number": "ABC1232",
        "Toll Tag Type": "Regular",
        "Data Source": "GK",
        "Total Trips": 1,
        "Trip Fees Beginning": "01/01/2024",
        "Trip Fees Ending": "01/31/2024",
        "Trip Fee": 2,
        "Paid": true,
        "Total Amount": 2,
        "Invoice #": 1004,
        "Total Amount Due": 2,
        "Date Due": "10/31/2024",
        "timestamp": 1729536697
    },
    {
        "Name": "Robert Brown",
        "Address": "101 Maple St",
        "City": "Houston",
        "State": "TX",
        "Zip": "77001",
        "Phone 1": "444-444-4444",
        "Phone 2": "999-999-9999",
        "Email": "robert@example.com",
        "DL": "DL-103",
        "Company Name": "Robert Corp",
        "Card Number": "DFW.02241720",
        "Read At": "5/16/24 7:50",
        "Plaza": "Plaza 4",
        "License Plate State": "TX",
        "License Plate Number": "ABC1233",
        "Toll Tag Type": "Regular",
        "Data Source": "GK",
        "Total Trips": 1,
        "Trip Fees Beginning": "01/01/2024",
        "Trip Fees Ending": "01/31/2024",
        "Trip Fee": 2,
        "Paid": true,
        "Total Amount": 2,
        "Invoice #": 1005,
        "Total Amount Due": 2,
        "Date Due": "10/31/2024",
        "timestamp": 1729536697
    },
    {
        "Name": "Emily Davis",
        "Address": "202 Elm St",
        "City": "Phoenix",
        "State": "AZ",
        "Zip": "85001",
        "Phone 1": "333-333-3333",
        "Phone 2": "666-666-6666",
        "Email": "emily@example.com",
        "DL": "DL-104",
        "Company Name": "Emily Corp",
        "Card Number": "DFW.02241720",
        "Read At": "5/16/24 7:50",
        "Plaza": "Plaza 5",
        "License Plate State": "AZ",
        "License Plate Number": "ABC1234",
        "Toll Tag Type": "Regular",
        "Data Source": "GK",
        "Total Trips": 1,
        "Trip Fees Beginning": "01/01/2024",
        "Trip Fees Ending": "01/31/2024",
        "Trip Fee": 2,
        "Paid": true,
        "Total Amount": 2,
        "Invoice #": 1006,
        "Total Amount Due": 2,
        "Date Due": "10/31/2024",
        "timestamp": 1729536697
    },
    {
        "Name": "John Doe",
        "Address": "123 Main St",
        "City": "New York",
        "State": "NY",
        "Zip": "10001",
        "Phone 1": "123-456-7890",
        "Phone 2": "707-707-7070",
        "Email": "john@example.com",
        "DL": "DL-100",
        "Company Name": "John Corp",
        "Card Number": "DFW.02241720",
        "Read At": "5/16/24 7:50",
        "Plaza": "Plaza 1",
        "License Plate State": "NY",
        "License Plate Number": "ABC1230",
        "Toll Tag Type": "Regular",
        "Data Source": "GK",
        "Total Trips": 1,
        "Trip Fees Beginning": "01/01/2024",
        "Trip Fees Ending": "01/31/2024",
        "Trip Fee": 2,
        "Paid": true,
        "Total Amount": 2,
        "Invoice #": 1002,
        "Total Amount Due": 2,
        "Date Due": "10/31/2024",
        "timestamp": 1729536697
    },
    {
        "Name": "Jane Smith",
        "Address": "456 Oak Ave",
        "City": "Los Angeles",
        "State": "CA",
        "Zip": "90001",
        "Phone 1": "987-654-3210",
        "Phone 2": "777-777-7777",
        "Email": "jane@example.com",
        "DL": "DL-101",
        "Company Name": "Jane Corp",
        "Card Number": "DFW.02241720",
        "Read At": "5/16/24 7:50",
        "Plaza": "Plaza 2",
        "License Plate State": "CA",
        "License Plate Number": "ABC1231",
        "Toll Tag Type": "Regular",
        "Data Source": "GK",
        "Total Trips": 1,
        "Trip Fees Beginning": "01/01/2024",
        "Trip Fees Ending": "01/31/2024",
        "Trip Fee": 2,
        "Paid": true,
        "Total Amount": 2,
        "Invoice #": 1003,
        "Total Amount Due": 2,
        "Date Due": "10/31/2024",
        "timestamp": 1729536697
    },
    {
        "Name": "Alice Johnson",
        "Address": "789 Pine Rd",
        "City": "Chicago",
        "State": "IL",
        "Zip": "60601",
        "Phone 1": "555-555-5555",
        "Phone 2": "333-333-3333",
        "Email": "alice@example.com",
        "DL": "DL-102",
        "Company Name": "Alice Corp",
        "Card Number": "DFW.02241720",
        "Read At": "5/16/24 7:50",
        "Plaza": "Plaza 3",
        "License Plate State": "IL",
        "License Plate Number": "ABC1232",
        "Toll Tag Type": "Regular",
        "Data Source": "GK",
        "Total Trips": 1,
        "Trip Fees Beginning": "01/01/2024",
        "Trip Fees Ending": "01/31/2024",
        "Trip Fee": 2,
        "Paid": true,
        "Total Amount": 2,
        "Invoice #": 1004,
        "Total Amount Due": 2,
        "Date Due": "10/31/2024",
        "timestamp": 1729536697
    },
    {
        "Name": "Robert Brown",
        "Address": "101 Maple St",
        "City": "Houston",
        "State": "TX",
        "Zip": "77001",
        "Phone 1": "444-444-4444",
        "Phone 2": "999-999-9999",
        "Email": "robert@example.com",
        "DL": "DL-103",
        "Company Name": "Robert Corp",
        "Card Number": "DFW.02241720",
        "Read At": "5/16/24 7:50",
        "Plaza": "Plaza 4",
        "License Plate State": "TX",
        "License Plate Number": "ABC1233",
        "Toll Tag Type": "Regular",
        "Data Source": "GK",
        "Total Trips": 1,
        "Trip Fees Beginning": "01/01/2024",
        "Trip Fees Ending": "01/31/2024",
        "Trip Fee": 2,
        "Paid": true,
        "Total Amount": 2,
        "Invoice #": 1005,
        "Total Amount Due": 2,
        "Date Due": "10/31/2024",
        "timestamp": 1729536697
    },
    {
        "Name": "Emily Davis",
        "Address": "202 Elm St",
        "City": "Phoenix",
        "State": "AZ",
        "Zip": "85001",
        "Phone 1": "333-333-3333",
        "Phone 2": "666-666-6666",
        "Email": "emily@example.com",
        "DL": "DL-104",
        "Company Name": "Emily Corp",
        "Card Number": "DFW.02241720",
        "Read At": "5/16/24 7:50",
        "Plaza": "Plaza 5",
        "License Plate State": "AZ",
        "License Plate Number": "ABC1234",
        "Toll Tag Type": "Regular",
        "Data Source": "GK",
        "Total Trips": 1,
        "Trip Fees Beginning": "01/01/2024",
        "Trip Fees Ending": "01/31/2024",
        "Trip Fee": 2,
        "Paid": true,
        "Total Amount": 2,
        "Invoice #": 1006,
        "Total Amount Due": 2,
        "Date Due": "10/31/2024",
        "timestamp": 1729536697
    }
];

const styles = {
    tableCell: {
      width: '200px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
};
  

const InvoicesTable = ({ flightDate }) => {
    const [invoices, setInvoices] = useState(dummyData);
    const [loading, setLoading] = useState(false);
    const tableContainerRef = useRef(null);
    const [hasMoreInvoices, setHasMoreInvoices] = useState(true);

    const handleScroll = useCallback(() => {
        if (tableContainerRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = tableContainerRef.current;
        if (scrollTop + clientHeight >= scrollHeight - 100 && !loading && hasMoreInvoices) {
            const lastEvent = invoices[invoices.length - 1];
            const lastTimestamp = lastEvent ? lastEvent.timestamp - 1 : null;
            //getInvoices(lastTimestamp);
        }
        }
    }, [loading, hasMoreInvoices, invoices]);

    useEffect(() => {
        const container = tableContainerRef.current;
        if (container) {
        container.addEventListener('scroll', handleScroll);
        return () => container.removeEventListener('scroll', handleScroll);
        }
    }, [handleScroll]);


    return (
        <Paper style={{ padding: 10 }}>
            <TimeFrameContainer 
                title="Past Invoices"
                downloadReportsEnabled={true}
                downloadData={invoices}
            />
            <TableContainer ref={tableContainerRef} style={{ height: 450, overflowY: 'auto' }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            {[
                                'Name', 'Address', 'City', 'State', 'Zip', 'Phone 1', 'Phone 2', 'Email', 'DL', 
                                'Company Name', 'Card Number', 'Read At', 'Plaza', 'License Plate State', 
                                'License Plate Number', 'Toll Tag Type', 'Data Source', 'Total Trips', 
                                'Trip Fees Beginning', 'Trip Fees Ending', 'Trip Fee', 'Paid', 'Total Amount', 
                                'Invoice #', 'Total Amount Due', 'Date Due', 'Time Created'
                            ].map((heading) => (
                                <TableCell key={heading} sx={styles.tableCell}>
                                    {heading}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {invoices.map((row, i) => (
                            <TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell sx={styles.tableCell} component="th" scope="row">
                                    {row.Name}
                                </TableCell>
                                <TableCell sx={styles.tableCell} align="right">{row.Address}</TableCell>
                                <TableCell sx={styles.tableCell} align="right">{row.City}</TableCell>
                                <TableCell sx={styles.tableCell} align="right">{row.State}</TableCell>
                                <TableCell sx={styles.tableCell} align="right">{row.Zip}</TableCell>
                                <TableCell sx={styles.tableCell} align="right">{row['Phone 1']}</TableCell>
                                <TableCell sx={styles.tableCell} align="right">{row['Phone 2']}</TableCell>
                                <TableCell sx={styles.tableCell} align="right">{row.Email}</TableCell>
                                <TableCell sx={styles.tableCell} align="right">{row.DL}</TableCell>
                                <TableCell sx={styles.tableCell} align="right">{row['Company Name']}</TableCell>
                                <TableCell sx={styles.tableCell} align="right">{row['Card Number']}</TableCell>
                                <TableCell sx={styles.tableCell} align="right">{row['Read At']}</TableCell>
                                <TableCell sx={styles.tableCell} align="right">{row.Plaza}</TableCell>
                                <TableCell sx={styles.tableCell} align="right">{row['License Plate State']}</TableCell>
                                <TableCell sx={styles.tableCell} align="right">{row['License Plate Number']}</TableCell>
                                <TableCell sx={styles.tableCell} align="right">{row['Toll Tag Type']}</TableCell>
                                <TableCell sx={styles.tableCell} align="right">{row['Data Source']}</TableCell>
                                <TableCell sx={styles.tableCell} align="right">{row['Total Trips']}</TableCell>
                                <TableCell sx={styles.tableCell} align="right">{row['Trip Fees Beginning']}</TableCell>
                                <TableCell sx={styles.tableCell} align="right">{row['Trip Fees Ending']}</TableCell>
                                <TableCell sx={styles.tableCell} align="right">{row['Trip Fee']}</TableCell>
                                <TableCell sx={styles.tableCell} align="right">{row.Paid ? 'Yes' : 'No'}</TableCell>
                                <TableCell sx={styles.tableCell} align="right">{row['Total Amount']}</TableCell>
                                <TableCell sx={styles.tableCell} align="right">{row['Invoice #']}</TableCell>
                                <TableCell sx={styles.tableCell} align="right">{row['Total Amount Due']}</TableCell>
                                <TableCell sx={styles.tableCell} align="right">{row['Date Due']}</TableCell>
                                <TableCell sx={styles.tableCell} align="right">{convertToLocal(row.timestamp)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                 </Table>
                {loading && (
                    <Box display="flex" justifyContent="center" alignItems="center" p={2}>
                        <CircularProgress /> 
                    </Box>
                )}
            </TableContainer>
        </Paper>
    );
};

const mapStateToProps = (state) => {
    return {
        flightDate: state.filterFieldsBarReducer.flightDate,
    };
};

export default connect(mapStateToProps)(InvoicesTable);

import React, { useEffect, useState, useRef, useCallback } from 'react';
import { getParkingQueueEvents } from "api/endpoints/parkingQueue";
import { connect } from 'react-redux';
import { convertToLocal } from 'utils/timeConversions';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import TimeFrameContainer from 'components/TimeFrameContainer/TimeFrameContainer';
import DropDownMenu from "components/DropDownMenu/DropDownMenu";

const ParkingReportTable = ({ flightDate }) => {
  const [parkingEvents, setParkingEvents] = useState([]);
  const [event, setEvent] = useState(null);
  const [loading, setLoading] = useState(false);
  const tableContainerRef = useRef(null);
  const [hasMoreEvents, setHasMoreEvents] = useState(true);

  const events = [
    "Entered Queue",
    "Exited Queue",
    "Entered Parking Zone",
    "Exited Reserved Parking Spot From",
    "Exited From Red Zone",
    "Exited Parking Spot from Leave Parking Spot Button",
    "Kicked Out of Parking Spot After 7 Hours",
    "User is at",
    "No Filter"
  ];

  const getEvents = async (lastTimestamp = null) => {
    if (loading || !hasMoreEvents) return; 
    setLoading(true);
    try {
      const timestamp = lastTimestamp ? lastTimestamp : flightDate.getTime() / 1000;
      const response = await getParkingQueueEvents(
        timestamp, 
        event !== "No Filter" ? event : null
      );
  
      setParkingEvents((prevEvents) => {
        const lastEvent = prevEvents[prevEvents.length - 1];
        const lastEventFromResponse = response.data.data[response.data.data.length - 1];
        const lastTimestampState = lastEvent ? lastEvent.timestamp : null;
        const lastTimestampFromResponse = lastEventFromResponse ? lastEventFromResponse.timestamp : null;
  
        if (lastTimestampState !== lastTimestampFromResponse || !prevEvents.length) {
          return [...prevEvents, ...response.data.data]; 
        } else {
          setHasMoreEvents(false);
          return prevEvents;
        }
      });
  
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    setParkingEvents([]);
    getEvents();
  }, [flightDate, event]);

  const handleScroll = useCallback(() => {
    if (tableContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = tableContainerRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 100 && !loading && hasMoreEvents) {
        const lastEvent = parkingEvents[parkingEvents.length - 1];
        const lastTimestamp = lastEvent ? lastEvent.timestamp - 1 : null;
        getEvents(lastTimestamp);
      }
    }
  }, [loading, hasMoreEvents, parkingEvents]);

  useEffect(() => {
    const container = tableContainerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
      return () => container.removeEventListener('scroll', handleScroll);
    }
  }, [handleScroll]);


  return (
    <Paper style={{ padding: 10 }}>
      <TimeFrameContainer 
        title="Parking Events Table"
        downloadReportsEnabled={true}
        downloadData={parkingEvents}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: { xs: "center", sm: "flex-end" },
          padding: 1,
        }}
      >
        <DropDownMenu
          listItems={events}
          setListItem={setEvent}
          listItemSelected={event}
        />
      </Box>
      <TableContainer ref={tableContainerRef} style={{ height: 450, overflowY: 'auto' }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Company Name</TableCell>
              <TableCell align="right">Taxi Number</TableCell>
              <TableCell align="right">First And Last Name</TableCell>
              <TableCell align="right">Event</TableCell>
              <TableCell align="right">Time of Event</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {parkingEvents.map((row, i) => (
              <TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {row.companyName}
                </TableCell>
                <TableCell align="right">{row.taxiNumber}</TableCell>
                <TableCell align="right">{row.firstAndLastName}</TableCell>
                <TableCell align="right">{row.event}</TableCell>
                <TableCell align="right">{convertToLocal(row.timestamp)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {loading && (
          <Box display="flex" justifyContent="center" alignItems="center" p={2}>
            <CircularProgress /> 
          </Box>
        )}
      </TableContainer>
    </Paper>
  );
};

const mapStateToProps = (state) => {
  return {
    flightDate: state.filterFieldsBarReducer.flightDate,
  };
};

export default connect(mapStateToProps)(ParkingReportTable);

import React, { useEffect, useRef, useState } from 'react';
import { Paper } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import {
  Chart, Filler, LinearScale, LineController,
  LineElement, PointElement, TimeScale, Tooltip
} from 'chart.js';
import TimeFrameContainer from 'components/TimeFrameContainer/TimeFrameContainer';
import { formatForUSCurrency } from 'utils/numberFormatting';
import { connect } from 'react-redux';
import { lightGrey } from 'styles/colors';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: 20
  },
  timeFrameContainer: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down('xs')]: {
      textAlign: "center"
    }
  },
  selectionContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    [theme.breakpoints.down('xs')]: {
      justifyContent: "center",
      paddingTop: 10,
      paddingBottom: 10
    }
  }
}));

const convertToMonth = (timestamp) => {
  return new Date(timestamp).toLocaleString('en-US', {
    month: 'short',
    year: 'numeric',
  });
};

const exampleRevenueData = {
  labels: [
    '2023-01-01', '2023-02-01', '2023-03-01', '2023-04-01', '2023-05-01', 
    '2023-06-01', '2023-07-01', '2023-08-01', '2023-09-01', '2023-10-01', 
    '2023-11-01', '2023-12-01'
  ],
  data: [2000000, 2000000, 3000000, 4000000, 5000000, 6400000, 7100000, 8200000, 9500000, 15000000, 22000000, 35000000]
};

const RevenueReportGraph = (props) => {
  const classes = useStyles();
  const canvas = useRef(null);
  const [timeFrame, setTimeFrame] = useState('Last Year');
  const [labels] = useState(exampleRevenueData.labels);
  const [datasetData] = useState(exampleRevenueData.data);

  useEffect(() => {
    Chart.register(LineController, LineElement, Filler, PointElement, LinearScale, TimeScale, Tooltip);
  }, []);

  useEffect(() => {
    const ctx = canvas.current;
    let chart;
    if (labels && datasetData) {
      const formattedLabels = labels.map((label) => convertToMonth(Date.parse(label)));

      if (chart !== undefined) {
        chart.destroy();
      }

      chart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: formattedLabels,
          datasets: [
            {
              data: datasetData,
              borderColor: '#FFA500',  // Orange color for line
              fill: false,
              backgroundColor: '#FFA500',
              borderWidth: 2,
              tension: 0,
              pointRadius: 3,
              pointHoverRadius: 5,
              pointBackgroundColor: '#FFA500'
            }
          ],
        },
        options: {
          layout: {
            padding: 20,
          },
          scales: {
            y: {
              grid: {
                drawBorder: false,
                beginAtZero: true,
              },
              ticks: {
                maxTicksLimit: 5,
                callback: (value) => formatForUSCurrency(value),
              },
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              callbacks: {
                title: () => false, // Disable tooltip title
                label: (context) => formatForUSCurrency(context.parsed.y),
              },
            },
          },
          interaction: {
            intersect: false,
            mode: 'nearest',
          },
          maintainAspectRatio: false,
          resizeDelay: 200,
        },
      });
    }
    return () => chart.destroy();
  }, [labels, datasetData]);

  return (
    <Paper className={classes.paper}>
        <TimeFrameContainer 
            title="Invoiced Amount Over Time" 
            timeFrame={timeFrame}
            setTimeFrame={setTimeFrame} 
            textColor={lightGrey}
        />
        {labels && datasetData && (
            <div>
              <canvas ref={canvas} width={595} height={248}></canvas>
            </div>
        )}
    </Paper>
  );
};

const mapStateToProps = (state) => {
  return {
    flightDate: state.filterFieldsBarReducer.flightDate
  };
};

export default connect(mapStateToProps)(RevenueReportGraph);

import parameters from "../parameters";

export const createMonthlyReport = async (fileData, month, year) => {
    return await parameters.post('/createMonthlyReport', {
        fileData,
        month,
        year
    });
};

export const saveMonthlyReport = async (monthlyReport) => {
    return await parameters.post('/saveMonthlyReport', {
        monthlyReport
    });
};
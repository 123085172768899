import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Link from '@material-ui/core/Link';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import { checkRegistrationCode, signup } from 'api/endpoints/auth';
import { auth } from "api/firebase";
import parameters from 'api/parameters';
import Image from 'assets/img/plane_1_1920.jpg';
import Icon from 'assets/img/transparent.png';
import airportStats from "constants/airportStats.json";
import {
  createUserWithEmailAndPassword, RecaptchaVerifier, sendEmailVerification, signInWithPhoneNumber
} from "firebase/auth";
import MuiPhoneNumber from 'material-ui-phone-number';
import React, { useMemo, useState } from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { updateAirport, updateAirports } from 'store/airport/actions';
import { updateAirlines } from 'store/filterFieldsBar/actions';
import { updateUser } from 'store/user/actions';
import Copyright from './Copyright';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: `url(${Image})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'dark' ? theme.palette.grey[900] : theme.palette.grey[50],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(4, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1)
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  loadingContainer:{
    justifyContent: "center",
    display: "flex",
    margin: 20
  },
  signUpContainer: {
    display: "flex",
    justifyContent: "space-between"
  }
}));

const SignUp = (props) => {
  const classes = useStyles();
  const { 
    changeAuth, 
    setRecapthaResponse, 
    updateUser,
    updateAirport, 
    updateAirports,
    updateAirlines,
  } = props;

  const [firstAndLastName, setFirstAndLastName] = useState(null)
  const [email, setEmail] = useState(null)
  const [phoneNumber, setPhoneNumber] = useState(null)
  const [password, setPassword] = useState(null)
  const [companyName, setCompanyName] = useState(null)
  const [registrationCode, setRegistrationCode] = useState(null)
  const [taxiNumber, setTaxiNumber] = useState(null)
  const [registeredAirportCode, setRegisteredAirportCode] = useState(null)
  const [licensePlate, setLicensePlate] = useState(null)

  const [isTaxi, setIsTaxi] = useState(true)
  const [showPassword, setShowPassword] = useState(false)
  const [loading, setLoading] = useState(false);
  
  const handleLicensePlateChange = (event) => {
    setLicensePlate(event.target.value)
  }

  const toggleIsTaxi = () => {
    setIsTaxi(!isTaxi)
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const setUpRecaptha = async() => {
    const recaptchaVerifier = new RecaptchaVerifier("recaptcha-container", {}, auth);
    recaptchaVerifier.render();
    return signInWithPhoneNumber(auth, phoneNumber.replace(" ", ""), recaptchaVerifier);
  }

  const sendOtp = async() => {
    const response = await setUpRecaptha();
    setRecapthaResponse(response)
  };

  const signUp = async(event) => {
    setLoading(true)
    event.preventDefault();
    try{
      await checkRegistrationCode(isTaxi, registeredAirportCode, registrationCode)
      if(isTaxi){
        await sendOtp()
        updateUser({
          airportCode: registeredAirportCode,
          firstAndLastName: firstAndLastName,
          companyName: companyName,
          taxiNumber: taxiNumber,
          phoneNumber: phoneNumber,
          accountBalance: 0,
          email: null,
          licensePlate: licensePlate
        })
      }else{
        updateUser({
          airportCode: registeredAirportCode,
          firstAndLastName: firstAndLastName,
          companyName: null,
          taxiNumber: null,
          phoneNumber: null,
          email: email
        })
        await createUserWithEmailAndPassword(auth, email, password)
        await signup({
          uid: auth.currentUser?.uid,
          airportCode: registeredAirportCode,
          firstAndLastName: firstAndLastName,
          companyName: null,
          taxiNumber: null,
          phoneNumber: null,
          email: email
        })
        await sendEmailVerification(auth.currentUser)
      }
      updateAirport(airportStats.filter((airport) => airport.code === registeredAirportCode)[0])
      updateAirports(airportStats)
      const airlines = await parameters.get("/getAirlines", { params: {
        airport: registeredAirportCode
      }});
      updateAirlines(airlines.data.data)
      changeAuth("VerifyUser")
      
      ReactGA.event({
        category: 'User',
        action: 'Signed Up'
      });

      setLoading(false)
    }catch(e){
      console.log(e);
      setLoading(false)
      ReactGA.exception({
        description: e.message,
        fatal: true
      });
      if(e?.response?.data?.data?.message){
        console.log(e?.response)
        alert(e?.response?.data?.data?.message)
      }else{
        console.log(e?.message)
        alert(e?.message);
      }
    }
  }
  
  const menuItemAirports = useMemo(() => {
    let airports = []
    airportStats.map((airport) => airports.push(airport.code))
    return airports.sort()
  }, [])

  return (
    <div className={classes.paper}>
      <Avatar className={classes.avatar} src={Icon}/>
      <Typography component="h1" variant="h5">
        Sign Up
      </Typography>
      <form className={classes.form} onSubmit={signUp}>
        <FormControlLabel control={<Switch checked={isTaxi} onChange={toggleIsTaxi}/>}
          label={<Typography variant="body2">Are you a taxi driver?</Typography>}
        />
        <FormControl style = {{width: "100%", marginTop: 16}}>
          <Select
            value={registeredAirportCode}
            required
            onChange={(value) => setRegisteredAirportCode(value?.target?.value)}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
          >
            <MenuItem value={null}>
              Select Airport
            </MenuItem>
            {menuItemAirports.map((airport, i) => <MenuItem key = {i} value={airport}>{airport}</MenuItem>)}
          </Select>
        </FormControl>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label="First and Last Name"
          autoFocus
          onChange={(value) => setFirstAndLastName(value?.target?.value)}
          inputProps={{ maxLength: 50 }}
        />

        {isTaxi ?
          <>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Company"
              onChange={(value) => setCompanyName(value?.target?.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Taxi Number"
              onChange={(value) => setTaxiNumber(value?.target?.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="License Plate"
              onChange={handleLicensePlateChange}
            />
            <MuiPhoneNumber 
              variant="outlined" 
              required 
              fullWidth 
              label="Phone Number" 
              margin="normal" 
              defaultCountry={'us'} 
              onChange={(value) => setPhoneNumber(value)}
            />
          </>
        : 
          <>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Email Address"
              autoComplete="email"
              onChange={(value) => setEmail(value?.target?.value)}
            />
            <OutlinedInput
              variant="outlined"
              margin="normal"
              required
              fullWidth
              placeholder="Password *"
              type={showPassword ? 'text' : 'password'}
              style={{marginTop: 16}}
              onChange={(value) => setPassword(value?.target?.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </>
        }
        
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label="Registration Code"
          onChange={(value) => setRegistrationCode(value?.target?.value)}
        />

        <div id="recaptcha-container"></div>

        {!loading ? 
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            className={classes.submit}
            type="submit"
          >
            Sign Up
          </Button>
        : 
          <div className={classes.loadingContainer}> <CircularProgress/> </div> 
        }
        <div className={classes.signUpContainer}> 
          <Link href="#" variant="body2" onClick={() => changeAuth("SignIn")}>
            Sign In.
          </Link>
          <Link href="#" variant="body2" onClick={() => changeAuth("ForgotPassword")}>
            Forgot password?
          </Link>
        </div>
        <Box mt={5}>
          <Copyright />
        </Box>
      </form>
    </div>
  );
}

const mapDispatchToProps = {
  updateAirport, 
  updateAirports,
  updateAirlines,
  updateUser
}

export default connect(null, mapDispatchToProps)(SignUp)